// Variables
$font-path: './fonts/';

// Import Poppins font from Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// Font Face
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  src: url('#{$font-path}Poppins-ThinItalic.ttf') format('ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  src: url('#{$font-path}Poppins-ExtraLightItalic.ttf') format('ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  src: url('#{$font-path}Poppins-LightItalic.ttf') format('ttf');
  font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    src: url('#{$font-path}Poppins-Italic.ttf') format('ttf');
    font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  src: url('#{$font-path}Poppins-MediumItalic.ttf') format('ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  src: url('#{$font-path}Poppins-SemiBoldItalic.ttf') format('ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src: url('#{$font-path}Poppins-BoldItalic.ttf') format('ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  src: url('#{$font-path}Poppins-ExtraBoldItalic.ttf') format('ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  src: url('#{$font-path}Poppins-BlackItalic.ttf') format('ttf');
  font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    src: url('#{$font-path}Poppins-Thin.ttf') format('ttf');
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    src: url('#{$font-path}Poppins-ExtraLight.ttf') format('ttf');
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: url('#{$font-path}Poppins-Light.ttf') format('ttf');
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('#{$font-path}Poppins-Regular.ttf') format('ttf');
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url('#{$font-path}Poppins-Medium.ttf') format('ttf');
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url('#{$font-path}Poppins-SemiBold.ttf') format('ttf');
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url('#{$font-path}Poppins-Bold.ttf') format('ttf');
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    src: url('#{$font-path}Poppins-ExtraBold.ttf') format('ttf');
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    src: url('#{$font-path}Poppins-Black.ttf') format('ttf');
    font-display: swap;
  }

// Usage
body {
  font-family: 'Poppins', sans-serif !important;
}
