@import "../../main_scss/main.scss";

/* Modal CSS Starts ====================================== */

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: transparent !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

input::placeholder {
  color: $primary-light;
}

.modal-backdrop.show {
  opacity: 1 !important;
  background-color: $primary-light;
}

.custom__modal {
  &.modal-full {
    width: 90%;
    max-width: 100%;

    @media (max-width: 767px) {
      margin: 10px auto;
    }
  }

  button.btn-close {
    outline: none;
    color: $grey-neutral;
    font-weight: 500;
    font-size: 20px;
    text-shadow: none;
    text-transform: uppercase;
    filter: invert(1);
    align-self: flex-start;
    // opacity: 1;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .modal-header {
    border-bottom-color: transparent;

    .modal-title {
      color: $primary-neutral;
      font-size: 40px;
      font-weight: 700;
    }
  }

  .modal-body {
    overflow: hidden;
    padding-bottom: 0;

    .contact-modal-desc {
      color: $primary-light;
      font-size: 18px;
      font-weight: 500;
      opacity: 0.6;
    }
  }

  .modal-content {
    overflow: hidden;
    background-color: $primary-dark;
    border-radius: 0;
  }

  .customForm {
    .form-floating {
      label {
        font-size: 12px;
        color: $primary-light !important;
        opacity: 0.8;

        &:after {
          background-color: $primary-dark !important;
        }
      }
    }

    .custom-input {
      font-size: 16px;
      background-color: $pop-color;
      border-color: $pop-color;
      color: $grey-neutral;
      border-radius: 0;
      // height: 100%;

      &.form-control-lg {
        height: 60px;
      }

      &.form-control-xl {
        height: 120px;
      }

      &:focus {
        border-color: $grey-neutral;
        outline: none;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
          0 0 8px rgba(156, 156, 156, 0.6);
      }
    }
  }
}

.success-message {
  background-color: $pop-color;
  letter-spacing: 1px;
  margin: 1rem 0;
  padding: 12px 16px;
  color: lightgreen;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.success-message.show {
  opacity: 1;
}
/* Modal CSS Ends ======================================== */
