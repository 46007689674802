@import "../../main_scss/main.scss";

@keyframes fade-in-large {
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0);
  }
}

/* Name Logo Starts ====================================== */
.navbar-brand {
  .logo {
    transform: translateY(-100px);
    animation: fade-in-large 1;
    animation-fill-mode: forwards;
    animation-duration: 0.7s;
    animation-delay: 0.5s;
  }
}

.logo {
  transform: translateY(-100px);
}

.logo {
  position: relative;
  padding: 15px 15px;
  background-color: $primary-dark;
  width: 80px;
  text-align: center;
  transition: all 250ms cubic-bezier(0.285, 0.105, 0.465, 1.015);
  transition-delay: 700ms;

  @media (max-width: 991px) {
    width: 150px;
  }

  &:hover {
    transition-delay: 0ms;
    direction: ltr;
    padding-left: 20px;
    padding-right: 20px;
    width: 150px;

    .hiddenn {
      text-align: left;
      height: 19px;
      width: 38px;
      transition-delay: 500ms;

      &:last-of-type .name {
        transform: translateY(0);
        transition-delay: 900ms;
      }
    }

    .name {
      transform: translateY(0);
      transition-delay: 700ms;
    }
  }

  p {
    display: inline-block;
    color: $primary-light;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.3;
    margin-bottom: 0;

    .initial {
      font-size: 18px;
      display: inline-block;
      transition: all 250ms cubic-bezier(0.285, 0.105, 0.465, 1.015);
      transition-delay: 400ms;
    }

    .hiddenn {
      display: inline-block;
      overflow: hidden;
      height: 0;
      width: 0;
      text-align: left;
      vertical-align: bottom;
      transition: all 250ms cubic-bezier(0.285, 0.105, 0.465, 1.015);
      transition-delay: 450ms;

      &:last-of-type .name {
        transform: translateY(-30px);
        transition-delay: 200ms;
      }

      @media (max-width: 991px) {
        height: 19px;
        width: 38px;

        &:last-of-type .name {
          transform: translateY(0);
        }
      }
    }

    .name {
      display: inline-block;
      transform: translateY(30px);
      transition: all 250ms cubic-bezier(0.285, 0.105, 0.465, 1.015);
      transition-delay: 350ms;

      @media (max-width: 991px) {
        transform: translateY(0);
      }
    }
  }
}
/* Name Logo Ends ====================================== */

.custom__header {
  .navbar-brand {
    height: auto;
    padding: 0 15px;

    @media (max-width: 991px) {
      padding: 0;
    }
  }

  .navbar-default {
    background-color: rgb(232 233 234 / 60%);
    border: none;
    border-radius: 0;
    padding: 0 30px;
    transition: all 0.5s ease-in-out;
    // background-color: $primary-light;

    @media (max-width: 991px) {
      padding: 0 15px;
      background-color: $primary-light;
    }

    .navbar-nav {
      .nav-link {
        margin: 2px 4px 0;
        text-transform: uppercase;
        font-weight: 600;
        color: $primary-neutral;
        cursor: pointer;

        &.active,
        &:hover {
          color: $primary-darker;
        }
      }
    }
  }

  .navbar-toggler {
    background-color: $primary-dark;

    // @media (max-width: 991px) {
    //   margin-right: 30px;
    // }

    &:hover,
    &:focus {
      outline: none;
      box-shadow: none;
    }

    &-icon {
      filter: invert(1);
    }
  }
}

/* Stroke Animation Starts ===================================  */
nav.stroke .navbar-nav .nav-link {
  position: relative;
  transition: all 0.5s;

  &:after {
    content: ".";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    color: transparent;
    background: $primary-dark;
    height: 2px;
    transition: all 0.5s;
  }

  &.active,
  &:hover {
    &:after {
      width: 100%;
    }
  }
}
/* Stroke Animation Ends ===================================  */
