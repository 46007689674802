@import "../../main_scss/main.scss";

.section__main {
  padding-top: 3%;
  margin-top: 60px;
  position: relative;
  z-index: 1;

  .main-info {
    padding: 0 45px;
    margin-top: 100px;
    margin-bottom: 178px;
  }

  .main-info {
    @media (max-width: 991px) {
      padding: 0 10px;
      margin-bottom: 100px;
    }

    @media (max-width: 767px) {
      margin-top: 50px;
    }

    > div {
      span {
        font-size: 36px;
        color: #707070;
      }
    }

    h1 {
      color: #262d38;
      font-size: 50px;
      font-weight: 700;
      margin: 35px 0;
    }

    h2 {
      font-size: 40px;
      font-weight: 300;
    }
  }

  .main-bgImg {
    position: relative;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;

    &-img {
      background: url("../../assets/images/Tahir-Kutty.jpeg");
      height: 650px;
      background-size: cover;
      background-position: center top;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;

      @media (max-width: 991px) {
        position: relative;
      }
    }
  }

  .main-button {
    position: absolute;
    bottom: 0;

    @media (max-width: 991px) {
      position: relative;
      text-align: center;
      margin: 40px 0;
    }
  }
}
