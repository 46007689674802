@import "../../main_scss/main.scss";

.section__contact {
  background: linear-gradient(
    to left,
    transparent 0%,
    transparent 10%,
    $primary-darker 10%,
    $primary-darker 100%
  );
  padding-top: 80px;
  padding-bottom: 80px;
  margin-top: -170px;
  position: relative;

  @media (max-width: 991px) {
    background: $primary-darker;
    padding-top: 20px;
    padding-bottom: 40px;
    margin-top: 0;
  }

  .contact__wrapper {
    @include d_flex;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 20px;

    @media (max-width: 991px) {
      margin: 0;
    }

    &--left {
      flex: 0 0 75%;
      max-width: 75%;

      @media (max-width: 1199px) {
        flex: 0 0 65%;
        max-width: 65%;
      }

      @media (max-width: 991px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    &--right {
      flex: 0 0 25%;
      max-width: 25%;

      @media (max-width: 1199px) {
        flex: 0 0 35%;
        max-width: 35%;
      }

      @media (max-width: 991px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    .contact__content {
      p {
        color: $grey-neutral;
        padding: 5px;
        font-size: 40px;
        font-weight: 500;
        margin-bottom: 0;
        line-height: 60px;

        @media (max-width: 991px) {
          text-align: center;
          margin-bottom: 30px;
        }

        @media (max-width: 767px) {
          font-size: 25px;
        }
      }
    }

    .contact__button {
      text-align: center;
    }
  }
}
