@import "../../main_scss/main.scss";

.section__skill {
    .skill__wrapper {
        @include d_flex;
        flex-wrap: wrap;
    }

    .skill__left {
        flex: 0 0 45%;
        max-width: 45%;
        margin-top: -150px;

        @media (max-width: 991px) {
            flex: 0 0 100%;
            max-width: 100%;
            margin-top: 0;
        }

        &--img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .skill__center {
        flex: 0 0 10%;
        max-width: 10%;

        @media (max-width: 991px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .skill__right {
        flex: 0 0 45%;
        max-width: 45%;

        @media (max-width: 991px) {
            flex: 0 0 100%;
            max-width: 100%;
        }

        .right__skills {
            padding: 0 30px;
            margin: 20px 0;
        }

        .skills {
            p {
                color: #707070;
                font-weight: 700;
                margin-bottom: 5px;
            }

            .progress {
                height: 6px;
                border-radius: 0;
                background-color: $white;
                margin-bottom: 20px;
            }

            .progress-bar {
                background-color: $primary-dark;
            }
        }
    }
    
}