@import "./main_scss/main.scss";

.theme-toggle {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
  margin-left: 20px;
  display: none;

  .hidden {
    display: none;
  }

  @media (max-width: 991px) {
    position: absolute;
    top: 12px;
    right: 12px;
    left: auto;
    transform: (translate(0, 0));
  }

  &:focus {
    outline: none;
  }
}

.scrollTop {
  position: fixed;
  right: 30px;
  bottom: 30px;
  background-color: $primary-light;
  color: $primary-darker;
  border: 1px solid $primary-darker;
  padding: 10px 16px;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  z-index: 98;
  transition: all 0.3s ease-in-out;

  @media (max-width: 767px) {
    right: 15px;
    bottom: 15px;
  }

  &.is-visible {
    opacity: 1;
  }

  &:hover {
    background-color: $primary-neutral;
    color: $primary-light;
    border-color: $primary-light;
  }

  @media (max-width: 600px) {
    padding: 6px 12px;
  }
}
